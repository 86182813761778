<template>
  <div v-if="!!asset || isLoading" class="p-3">
    <Icon v-if="assetId && isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
    <div v-if="asset && !isLoading">
      <div>
        <h1 class="text-heading-4 mb-4 max-w-3xl break-words">{{ assetDisplayName }}</h1>
        <p class="-mt-2 mb-4 italic">{{ adminAccountsStore.getAccountById(asset.accountId)?.name }}</p>

        <AssetSummaryPanel :asset="asset" :asset-jobs="assetJobs" />

        <div class="mt-4 flex flex-wrap gap-2">
          <WcButton
            text="Rerun Audit"
            icon="wc-carbon:redo"
            :is-disabled="asset.status === AssetStatus.archived"
            size="small"
            @click="handleTriggerAudit" />

          <WcButton
            v-if="asset.status !== AssetStatus.draft"
            text="Mark as Draft"
            icon="wc-carbon:pending"
            size="small"
            @click="handleStartRequestChanges" />

          <WcButton
            v-if="asset.status !== AssetStatus.ready"
            text="Mark as Ready"
            icon="wc-carbon:checkmark"
            size="small"
            @click="handleApproveAsset" />

          <WcButton
            v-if="asset.status !== AssetStatus.archived"
            text="Mark as Archived"
            icon="wc-carbon:pending"
            size="small"
            @click="handleChangeToArchived" />

          <WcButton
            v-if="asset.status === AssetStatus.ready"
            text="Mint EACs"
            icon="wc-carbon:stamp"
            :is-disabled="mintingButtonDebounce"
            size="small"
            @click="handleTriggerMinting" />

          <WcButton text="Delete Asset" color="alert" icon="wc-carbon:delete" size="small" @click="deleteAsset" />
        </div>
      </div>

      <nav class="my-4 flex flex-row gap-2 rounded-md bg-neutral-20 p-1">
        <router-link class="tab" :to="{ name: 'wc-admin-asset-metadata' }">Metadata</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-metadata-edit' }">Edit</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-internal-notes' }">Internal Notes</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-documents' }">Documents</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-audits' }">Audits</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-versions' }">Versions</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-activity' }">Activity</router-link>
      </nav>

      <router-view :asset="asset" @on-asset-update-state="handleUpdateAssetState"></router-view>
    </div>

    <WcModal
      v-if="!isLoading && asset"
      :is-open="isChangesRequiredDialogOpen"
      :header="`Request changes for ${assetDisplayName}`"
      @update:is-open="(isOpen) => (isChangesRequiredDialogOpen = isOpen)">
      <template #panel>
        <form ref="formRef" @submit.prevent="handleChangeToDraft">
          <label>
            Changes requested
            <textarea v-model="reviewNotes" rows="4" />
          </label>
        </form>
      </template>
      <template #footer>
        <WcButton text="Cancel" variant="secondary" size="small" @click="isChangesRequiredDialogOpen = false" />
        <WcButton text="Mark As Draft" size="small" @click="formRef?.requestSubmit()" />
      </template>
    </WcModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue"
import { useToast } from "vue-toastification"
import { Icon } from "@iconify/vue"
import type { Asset, AssetJob } from "@/models/asset"
import { AssetStatus, getAssetDisplayName } from "@/models/asset"
import { WcButton } from "@/components/button"
import WcModal from "@/components/WcModal.vue"
import AssetSummaryPanel from "@/modules/asset/components/AssetSummaryPanel.vue"
import { useAssetService } from "@/services/service-container"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"

const assetService = useAssetService()
const toast = useToast()
const adminAccountsStore = useAdminAccountsStore()

const emit = defineEmits(["on-asset-status-update"])
const props = defineProps<{ assetId: number | null }>()

const formRef = ref<HTMLFormElement | null>(null)
const asset = ref<Asset | undefined>()
const isChangesRequiredDialogOpen = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const reviewNotes = ref<string>("")
const reviewNotesInternal = ref<string>("")
const assetJobs = ref<AssetJob[]>([])

const assetDisplayName = computed(() => getAssetDisplayName(asset.value))

const loadAssetData = async (assetId: number) => {
  try {
    asset.value = await assetService.getAssetAdmin(assetId)
    reviewNotes.value = asset.value.reviewNotes || ""
    reviewNotesInternal.value = asset.value.reviewNotesInternal || ""
    assetJobs.value = await assetService.listAssetJobs(assetId)
  } catch (error) {
    console.error(`There was an error loading asset ${assetId}`, error)
  }
  isLoading.value = false
  mintingButtonDebounce.value = false
}

watchEffect(async () => {
  // The asset list endpoint doesn't include all asset info so we need to fetch
  // additional details from the getAssetAdmin endpoint
  if (props.assetId && !isNaN(props.assetId)) {
    isLoading.value = true
    loadAssetData(props.assetId)
  }
})

const submitAssetReview = async (reviewProps: { status?: AssetStatus; reviewNotesInternal?: string; reviewNotesExternal?: string }) => {
  if (!props.assetId) {
    return
  }
  // Merge inputs with existing state before sending the update to the API
  const review = {
    status: asset.value!.status,
    reviewNotesInternal: reviewNotesInternal.value,
    reviewNotesExternal: reviewNotes.value,
    ...reviewProps,
  }
  await assetService.submitAssetReview(props.assetId, review)
  await loadAssetData(props.assetId)
}

const handleTriggerAudit = async () => {
  try {
    await assetService.triggerAudit(props.assetId!)
    toast.success(`Audit triggered for ${assetDisplayName.value}`, {
      timeout: 3000,
    })
    location.reload()
  } catch (error) {
    const message = `There was a problem triggering an audit for ${assetDisplayName.value}`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
    console.error(message, error)
  }
}

const handleApproveAsset = async () => {
  try {
    await submitAssetReview({ status: AssetStatus.ready, reviewNotesExternal: "" })
    emit("on-asset-status-update", AssetStatus.ready)
    toast.success(`Approved ${assetDisplayName.value}`, { timeout: 3000 })
  } catch (error) {
    const message = `There was a problem approving ${assetDisplayName.value}`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
    console.error(message, error)
  }
}

const handleStartRequestChanges = () => {
  isChangesRequiredDialogOpen.value = true
}

const handleChangeToArchived = async () => {
  try {
    await submitAssetReview({ status: AssetStatus.archived })
    emit("on-asset-status-update", AssetStatus.archived)
    toast.success(`Changed ${assetDisplayName.value} to In review`, { timeout: 3000 })
  } catch (error) {
    const message = `There was a problem changing ${assetDisplayName.value} to In review`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
    console.error(message, error)
  }
}

const deleteAsset = async () => {
  if (asset.value && confirm(`Delete asset '${assetDisplayName.value}' (${asset.value.id})?`)) {
    await assetService.deleteAsset(asset.value.id)
    location.reload()
  }
}

const handleUpdateAssetState = (updatedAsset: Partial<Asset>) => {
  if (updatedAsset.reviewNotesInternal !== undefined) {
    reviewNotesInternal.value = updatedAsset.reviewNotesInternal
  }
  asset.value = {
    ...asset.value,
    ...updatedAsset,
  } as Asset
}

const mintingButtonDebounce = ref(false)
const handleTriggerMinting = async () => {
  if (asset.value && confirm(`Mint EACs for asset '${assetDisplayName.value}' (${asset.value.id})?`)) {
    mintingButtonDebounce.value = true

    await assetService.triggerMinting(asset.value.id)

    setTimeout(() => {
      mintingButtonDebounce.value = false
    }, 5000)
  }
}

const handleChangeToDraft = async () => {
  if (!props.assetId) {
    return
  }
  try {
    await submitAssetReview({
      status: AssetStatus.draft,
      reviewNotesExternal: reviewNotes.value,
    })
    reviewNotes.value = ""
    isChangesRequiredDialogOpen.value = false
    emit("on-asset-status-update", AssetStatus.draft)
    toast.success(`Changes requested for ${assetDisplayName.value}`, { timeout: 3000 })
  } catch (error) {
    const message = `There was a problem requesting changes for ${assetDisplayName.value}`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
    console.error(message, error)
  }
}
</script>

<style scoped lang="scss">
.tab {
  @apply px-4 py-2 rounded-md;
}

.router-link-active {
  @apply bg-neutral-30;
}
</style>
