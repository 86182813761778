<template>
  <Icon
    :icon="statusInfo?.icon ?? 'wc-carbon:circle-solid'"
    :title="statusInfo?.summary ?? props.status"
    class="mr-2 size-4 min-w-4 stroke-[4px] drop-shadow"
    :color="statusInfo.color ?? 'neutral-40'" />
</template>

<script setup lang="ts">
import { AssetAuditStatus, ASSET_AUDIT_STATUS } from "@/models/asset"
import { Icon } from "@iconify/vue"
import { computed } from "vue"

const props = defineProps<{ status: AssetAuditStatus }>()

const statusInfo = computed(() => {
  const result = ASSET_AUDIT_STATUS[props.status]
  console.log("props.auditStatus", props.status, result)
  return result
})
</script>
